import * as holiday_jp from '@holiday-jp/holiday_jp';

// {key: value} ペアのオブジェクトを、URL用のクエリ文字列 '?key=value' に変換する
// 例: queryConfigToString({key1: 'value1', key2: 'value2'}) => '?key1=value1&key2=value2'
export const queryConfigToString = (queryConfig: object): string => {
  if (queryConfig === undefined) {
    return ''
  }
  const keyAndValues = Object.entries(queryConfig)
  const queries = keyAndValues.map(keyValue => {
    return `${keyValue[0]}=${keyValue[1]}`
  })
  const query = queries.join('&')
  return `?${query}`
}

// 指定日の昨日を取得する
// 例: dateToYesterday(new Date("2024-04-01Z00:00:00")) => 2024-03-31T00:00:00.000Z
export const dateToYesterday = (date: Date): Date => {
  const yesterday = new Date(date)
  yesterday.setDate(yesterday.getDate() - 1)
  return yesterday
}

// 指定の日付から曜日と祝日を取得する。
// wday_holiday が true の場合に、祝日の場合は '祝' が付与される
// wday_holiday が false の場合に、祝日の場合は '祝' のみが出力される
// 例: formatDayOfWeek(new Date("2024-04-01Z00:00:00")) => '月'
// 例: formatDayOfWeek(new Date("2024-01-01Z00:00:00"), true) => '火・祝'
// 例: formatDayOfWeek(new Date("2024-01-01Z00:00:00"), false) => '祝'
export const formatDayOfWeek = (date: Date, wday_holiday: Boolean = false) => {
  if (date) {
    if (holiday_jp.isHoliday(date)) {
      return (wday_holiday ? ['日', '月', '火', '水', '木', '金', '土'][date.getDay()] + '・祝'
        : '祝')
    } else {
      return ['日', '月', '火', '水', '木', '金', '土'][date.getDay()]
    }
  }

  return '-'
}

// 指定の日付から曜日を取得する
// 例: daysOfWeek(new Date("2024-04-01Z00:00:00 ")) => '月'
export const daysOfWeek = (date: Date): '日' | '月' | '火' | '水' | '木' | '金' | '土' => {
  const dayOfWeek = ['日', '月', '火', '水', '木', '金', '土'][
    date.getDay()
  ]
  return dayOfWeek as '日' | '月' | '火' | '水' | '木' | '金' | '土'
}

// 日付のフォーマットを変換する
// 例
// formatDate(new Date("2024-01-15Z00:00:00 "), 'ja-long') => '1月15日（月）'
// formatDate(new Date("2024-01-15Z00:00:00 "), 'ja-short') => '1月15日（月）'
// formatDate(new Date("2024-01-15Z00:00:00 "), '/') => '2024/1/15（月）'
// formatDate(new Date("2024-01-15Z00:00:00 "), 'hoge') => '2024/1/15（月）'
export const formatDate = (date: Date, format: string) => {
  switch (format) {
    case 'ja-long':
      return `${date.getMonth() + 1}月${date.getDate()}日（${daysOfWeek(date)}）`
    case 'ja-short':
      return `${date.getMonth() + 1}月${date.getDate()}日（${daysOfWeek(date)}）`
    case '/':
      return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}（${daysOfWeek(date)}）`
  }
  return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}（${daysOfWeek(date)}）`
}

// [from, to] の範囲の数値を持つ配列を作成する。
// 選択ボックスでの使用を想定しているため、{text: :number, value: :number} の配列が返却される。
// suffixLabel が指定された場合、各textに suffixLabel を付与する
export const createNumOptions = (from: number, to: number, suffixLabel = '') => {
  const range = to - from + 1;
  const arr = Array.from(Array(range).keys()).map(val => val + from);

  return arr.map(val => {
    return {
      text: val.toString() + suffixLabel,
      value: val.toString()
    }
  });
}

// 年の選択肢
export const yearOptions = createNumOptions(1900, 2015).reverse();
// 月の選択肢
export const monthOptions = createNumOptions(1, 12);
// 日の選択肢
export const dayOptions = createNumOptions(1, 31);

// 広告で使用するプランを出力
export const getAdItem = (course: object) => {
  const plans = course.attributes.plans

  if (plans.hasOwnProperty('social_4weeks') && plans.social_4weeks.tax_included_price) {
    return plans.social_4weeks
      .tax_included_price
  } else {
    return Object.values(plans).filter((v) => v.id)
      .sort((a: any, b: any) => {
        // ['standard' -> 'student' -> ..]
        if (a.category_name > b.category_name) return 1
        if (a.category_name < b.category_name) return -1

        // Price を降順
        if (a.tax_included_price < b.tax_included_price) return -1
        if (a.tax_included_price > b.tax_included_price) return 1
      })[0]
      .tax_included_price
  }
}
